<template>
  <router-view />
  
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-size:.2rem;
  box-sizing: border-box;
}
</style>
